module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Gilda","MADE","Santral Light","Santral Light Italic"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-N9GJTRL","cookieName":"CookieConsent"},"facebookPixel":{"pixelId":"384613239634962","cookieName":"CookieConsent"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lori Books","short_name":"Lori","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/gatsby-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"377832bf1f2d60cebcca5f0a47cdbefa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
